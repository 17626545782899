
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import Highcharts from "highcharts";
import { ConsolidatedByMonthSerieDTO } from '@/data/dto/consolidated-by-month.dto';
import { MonthEnum } from '@/data/dto/month.dto';
import moment from "moment";

import HighchartsMixins from '@/mixins/highcharts.mixin';
import HighchartsUtil from "@/utils/highcharts.util";
import { numberToBrString } from "@/utils/number.util";

@Component({
  components: {
    DataFormatControlsContainer,
  }
})
export default class ConsolidatedByMonthChart extends Mixins(HighchartsMixins) {
  @Prop() private series!: ConsolidatedByMonthSerieDTO[];
  @Prop() private year!: number;
  @Prop({ default: 0 }) private defaultDecimalPlaces!:number;

  yKey: ChartDataType = 'audience';

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: ConsolidatedByMonthChart): Highcharts.Options {
    return {
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
          marker: {
            enabled: true,
            radius: 4,
            symbol: 'circle',
          }
        }
      } as Highcharts.PlotOptions,
      xAxis: {
        showEmpty:true,
        type: 'datetime',
        tickInterval: 30 * 24 * 60 * 60 * 1000, // mes, dia, hora, minuto, segundo
        labels: {
          events: {
            click: function() { context.onMonthClicked(this) }
          },
          format: '{value:%b}',
        },
        min: Date.UTC(context.year, 0, 1),
        max: Date.UTC(context.year, 11, 1),
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        split:false,
        xDateFormat: '%B',
      },
      series: [],
    };
  }

  generatePlotBands(ticksPositions: number[], selectedPosition = 0): Highcharts.XAxisPlotBandsOptions[] {
    return ticksPositions.map((tickPosition) => {
      return {
        color: tickPosition === selectedPosition ? '#dddddd' : "#ffffff",
        from: moment.utc(tickPosition).subtract(15, 'days').toDate().getTime(),
        to: moment.utc(tickPosition).add(15, 'days').toDate().getTime(),
      };
    });
  }

  onMonthClicked(label: any): void {
    const month = moment.utc(label.pos).month();
    const monthTimestamp = Date.UTC(this.year, month, 1);

    const hasData = this.series.some((tv) => tv.data.some((item) => item.x === monthTimestamp));

    if (!hasData) {
      this.$store.commit('showAlert', {
        message: 'Não há dados disponíveis para o mês selecionado.',
        type: 'warning',
      });
      return;
    }

    this.$emit('monthClicked', MonthEnum[month]);
    //muda a cor da plot band do mês selecionado
    const highchart:any = this.$refs.highchart;
    if (!highchart) return;
    highchart.chart.update({
      xAxis: {
        plotBands: this.generatePlotBands(label.axis.tickPositions, label.pos)
      }
    });
  }

  selectLastMonth() {
    this.getLastLabelWithData().then(({ position, tickPositions }) => {
      this.onMonthClicked({
        value: moment.utc(position).format('MM'),
        pos: position,
        axis: { tickPositions }
      });
    });
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: ConsolidatedByMonthSerieDTO[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  @Watch('series')
  renderChart(series: ConsolidatedByMonthSerieDTO[]): void {
    this.chartOptions = this.generateChartOptions(this);
    this.mountChartSeriesYAxis(series);
    this.selectLastMonth();
  }

  mounted(): void {
    this.renderChart(this.series);
    this.decimalPlaces = this.defaultDecimalPlaces;
  }
}
