import Vue from 'vue';
import Component from 'vue-class-component';
import Highcharts from "highcharts";

@Component
export default class HighChartsMixin extends Vue {
  decimalPlaces = 0;
  updateArgs = [true, true, {duration: 500}];
  chartOptions: Highcharts.Options = {};

  updateDataLabelDecimalPlaces(decimalPlaces: number): void {
    this.decimalPlaces = decimalPlaces;
    const highchart:any = this.$refs.highchart;
    if (!highchart) return;

    highchart.chart.update(this.chartOptions);
  }

  getLastLabelWithData(): Promise<Record<string, any>> {
    return new Promise((resolve) => {
      this.$nextTick(() => {
        const chart = (this.$refs.highchart as any)?.chart as Highcharts.Chart;
        const ticks = chart.xAxis?.[0]?.tickPositions;
        const lastAvailableDate = (chart.xAxis?.[0] as any)?.dataMax;

        if (!lastAvailableDate) { return resolve({}) }

        resolve({
          position: lastAvailableDate,
          tickPositions: ticks,
        });
      });
    });
  }
}
