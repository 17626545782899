import { numberToBrString } from "@/utils/number.util";
import { TargetBaseType, generateViewersProjectionLabel } from "@/utils/viewers-projection.util";

export interface IChartExtremes {
  min: number;
  max: number;
}

export interface AudienceTooltipData {
  title: string;
  series: AudienceTooltipDataSeries[];
  decimalPlaces?: number;
}

export interface AudienceTooltipDataSeries {
  color: string;
  label: string;
  audienceOrSharePercentage: number;
  viewersProjection?: string;
}

export interface IExtendedChartPoint extends Highcharts.Point {
  y: number;
  color: string;
  viewersProjection?: number;
  audience: number;
  share: number;
  program?: string;
}

export default class HighchartsUtil {
  static setYAxisValue<T = Record<string, any>[]>(data: T[], yAxisKey: string): T[] {
    return data.map((item: any) => {
      return { ...item, y: item[yAxisKey] };
    });
  }

  static renderAudienceHTMLTooltip(data: AudienceTooltipData, targetBaseType: TargetBaseType): string {
    const { title, series, decimalPlaces } = data;

    const lines = series.reduce((customHTML, currentSeries): string => {
      const showViewersProjection = typeof currentSeries.viewersProjection === 'string';
      const customRowSpan = showViewersProjection ? 2 : 1;
      const viewersProjectionLabel = generateViewersProjectionLabel(targetBaseType);

      let innerHTML = `
        <tr class="border-top align-top">
          <td rowspan="${customRowSpan}" class="p-1" style="color: ${currentSeries.color};">&#x2022;</td>
          <td rowspan="${customRowSpan}" class="p-1 pr-2">${currentSeries.label}</td>
          <td class="font-weight-bold text-left p-1">%: ${numberToBrString(currentSeries.audienceOrSharePercentage, decimalPlaces)}</td>
        </tr>
      `;

      if (showViewersProjection) {
        innerHTML += `
          <tr>
            <td class="font-weight-bold text-left p-1">${viewersProjectionLabel}: ${currentSeries.viewersProjection}</td>
          </tr>
        `;
      }

      customHTML += innerHTML;
      return customHTML;
    }, '');

    const tooltipHTML = `
      <p>${title}</p>
      <table style="border-collapse: collapse;">${lines}</table>
    `;

    return tooltipHTML;
  }
}
