export type Month = 'JANEIRO' | 'FEVEREIRO' | 'MARCO' | 'ABRIL' | 'MAIO' | 'JUNHO' | 'JULHO' | 'AGOSTO' | 'SETEMBRO' | 'OUTUBRO' | 'NOVEMBRO' | 'DEZEMBRO';

export enum MonthEnum {
  JANEIRO,
  FEVEREIRO,
  MARCO,
  ABRIL,
  MAIO,
  JUNHO,
  JULHO,
  AGOSTO,
  SETEMBRO,
  OUTUBRO,
  NOVEMBRO,
  DEZEMBRO,
}