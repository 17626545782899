
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import Highcharts from "highcharts";
import { ConsolidatedByYearSerieDTO } from '@/data/dto/consolidated-by-year.dto';
import moment from "moment";
import HighchartsMixins from '@/mixins/highcharts.mixin';
import HighchartsUtil from "@/utils/highcharts.util";
import { numberToBrString } from "@/utils/number.util";

@Component({
  components: {
    DataFormatControlsContainer,
  }
})

export default class ConsolidatedByYearChart extends Mixins(HighchartsMixins) {
  @Prop() private series!: ConsolidatedByYearSerieDTO[];
  @Prop({ default: 0 }) private defaultDecimalPlaces!:number;

  yKey: ChartDataType = 'audience';

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context:any): Highcharts.Options {
    const config = {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
        }
      } as Highcharts.PlotOptions,
      xAxis: {
        type: 'datetime',
        tickInterval: 12 * 30 * 24 * 60 * 60 * 1000, // ano, mes, dia, hora, minuto, segundo
        labels: {
          events: {
            click: function() { context.onYearClicked(this) }
          },
          format: '{value:%Y}',
        },
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        split:false,
        xDateFormat: '%Y',
      },
      series: [],
    };
    return config;
  }

  generatePlotBands(ticksPositions: number[], selectedPosition = 0): Highcharts.XAxisPlotBandsOptions[] {
    return ticksPositions.map((tickPosition) => {
      return {
        color: tickPosition === selectedPosition ? '#dddddd' : "#ffffff",
        from: moment.utc(tickPosition).subtract(6, 'months').toDate().getTime(),
        to: moment.utc(tickPosition).add(6, 'months').toDate().getTime(),
      };
    });
  }

  onYearClicked(label: any, ...args: any[]): void {
    //emite evento de ano clickado
    const year = label.value;
    const yearTimestamp = Date.UTC(year, 0, 1);

    const hasData = this.series.some((tv) => tv.data.some((item) => item.x === yearTimestamp));

    if (!hasData) {
      this.$store.commit('showAlert', {
        message: 'Não há dados disponíveis para o ano selecionado.',
        type: 'warning',
      });
      return;
    }

    this.$emit('yearClicked', year);

    //muda a cor da plot band do ano selecionado
    const highchart:any = this.$refs.highchart;
    if (!highchart) return;
    highchart.chart.update({
      xAxis: {
        plotBands: this.generatePlotBands(label.axis.tickPositions, label.pos)
      }
    });
  }

  selectLastYear() {
    this.getLastLabelWithData().then(({ position, tickPositions }) => {
      this.onYearClicked({
        value: moment.utc(position).format('YYYY'),
        pos: position,
        axis: { tickPositions }
      });
    });
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: ConsolidatedByYearSerieDTO[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  @Watch('series')
  renderChart(series: ConsolidatedByYearSerieDTO[]): void {
    this.mountChartSeriesYAxis(series);
    this.selectLastYear();
  }

  mounted(): void {
    this.chartOptions = this.generateChartOptions(this);
    this.renderChart(this.series);
    this.decimalPlaces = this.defaultDecimalPlaces;
  }
}
