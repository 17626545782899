
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import Highcharts from "highcharts";
import { ConsolidatedByDaySerieDTO, ConsolidatedByDayProgramList } from '@/data/dto/consolidated-by-day.dto';
import moment from "moment";
import { Month, MonthEnum } from "@/data/dto/month.dto";
import HighchartsMixins from '@/mixins/highcharts.mixin';
import HighchartsUtil from "@/utils/highcharts.util";
import { numberToBrString } from "@/utils/number.util";

@Component({
  components: {
    DataFormatControlsContainer,
  }
})
export default class ConsolidatedByDayChart extends Mixins(HighchartsMixins) {
  @Prop() private series!: ConsolidatedByDaySerieDTO[];
  @Prop({ default: () => []}) private programs!: ConsolidatedByDayProgramList[];
  @Prop() private year!: number;
  @Prop() private month!: Month;
  @Prop({ default: 0 }) private defaultDecimalPlaces!:number;

  yKey: ChartDataType = 'audience';

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: ConsolidatedByDayChart): Highcharts.Options {
    return {
      chart: {
        type: 'line',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
          marker: {
            enabled: true,
            radius: 4,
            symbol: 'circle',
          }
        }
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 1 * 24 * 3600 * 1000,
        labels: {
          format: '{value:%d}<br>{value:%a}',
        },
        min: Date.UTC(context.year, MonthEnum[context.month], 1),
        max: Date.UTC(context.year, MonthEnum[context.month] + 1, 0),
      },
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
        split:false,
        //se houver programas, gera tooltip customizada, senão usa a tooltip padrão
        formatter: context.programs.length ? function():string { return context.generateProgramsTooltip(this.x); } : undefined,
      },
      series: [],
    };
  }

  generateProgramsTooltip(day: number): string {
    const selectedDay = this.programs.filter(item => item.day === day)[0];
    if (!selectedDay) return '';

    const formattedDay = moment.utc(day).format('DD');

    //todo: Colocar os programas filhos "dentro" do programa pai
    //gera a tooltip baseada na lista de programas de cada dia
    let tooltipHTML = `<small><strong>Programas</strong></small><table>`;
    selectedDay.programs.forEach(program => {
      const decorationStyle = program.isChildOf ? '- &nbsp' : '';
      tooltipHTML += `
        <tr style="color:${program.color};">
          <td style="text-align:left">${decorationStyle}${program.name}</td>
          <td style="text-align:right">${program.exhibitionPercentage}%</td>
        </tr>`;
    });
    tooltipHTML += '</table>';
    return tooltipHTML;
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: ConsolidatedByDaySerieDTO[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  @Watch('series')
  renderChart(series: ConsolidatedByDaySerieDTO[]): void {
    this.chartOptions = this.generateChartOptions(this);
    this.mountChartSeriesYAxis(series);
  }

  mounted(): void {
    this.renderChart(this.series);
    this.decimalPlaces = this.defaultDecimalPlaces;
  }
}
